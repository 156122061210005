import React from "react";
import {  Card, Stack,  Typography, } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const TitleHeader = ({ titles = [] }) => {
  return (
    <Card sx={{ p: 1, mr: 4 }}>
      <Stack sx={{ alignItems: "center", flexDirection: "row" }}>
        {titles.map((title, index) => (
          <Stack direction={"row"} alignItems={"center"} key={index}>
            <Typography sx={{fontWeight:"bold"}} >
              {title}
            </Typography>
            {index !== titles.length - 1 ? <ArrowForwardIosIcon /> : null}
          </Stack>
        ))}
      </Stack>
    </Card>
  );
};

export default TitleHeader;
