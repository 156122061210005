import { Box, Typography, Paper } from "@mui/material";

const WidgetContainer = ({ title, children }) => {
  return (
    <Box>
      <Paper elevation={8} sx={{ padding: 1 }}>
        <Typography variant="body1" align="center" sx={{ mb: 2, mt: 2 }}>
          {title}
        </Typography>
        {children}
      </Paper>
    </Box>
  );
};

export default WidgetContainer;
