import { createSlice } from "@reduxjs/toolkit";
import { getSalesCallsBySalesPerson, getSalesCallsForCustomer } from "./actions";

const initialState = {
  salesCallsBySalesPerson: {
    data: [],
    error: null,
    loading: false,
  },
  salesCallsForCustomer: {
    data: [],
    error: null,
    loading: false,
  },
};

const commonSlice = createSlice({
  name: "sales",
  initialState,
  reducers: {},
  extraReducers: {
    [getSalesCallsBySalesPerson.pending]: (state) => {
      state.loading = true;
      state.salesCallsBySalesPerson.loading = true;
      state.salesCallsBySalesPerson.error = null;
    },
    [getSalesCallsBySalesPerson.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.salesCallsBySalesPerson.loading = false;
      state.salesCallsBySalesPerson.data = payload;
    },
    [getSalesCallsBySalesPerson.rejected]: (state, { payload }) => {
      state.loading = false;
      state.salesCallsBySalesPerson.loading = false;
      state.salesCallsBySalesPerson.error = payload;
    },
    [getSalesCallsForCustomer.pending]: (state) => {
      state.loading = true;
      state.salesCallsForCustomer.loading = true;
      state.salesCallsForCustomer.error = null;
    },
    [getSalesCallsForCustomer.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.salesCallsForCustomer.loading = false;
      state.salesCallsForCustomer.data = payload;
    },
    [getSalesCallsForCustomer.rejected]: (state, { payload }) => {
      state.loading = false;
      state.salesCallsForCustomer.loading = false;
      state.salesCallsForCustomer.error = payload;
    },
  },
});

export default commonSlice.reducer;
