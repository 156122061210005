import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Stack, Typography } from "@mui/material";
import { readAsyncStorageValues } from "../../features/common/actions";
import ServiceHoursBy4YearsNationalBar from "../../components/ServiceHoursBy4YearsNationalBar";
import ServiceHoursBy4YearsByStateBar from "../../components/ServiceHoursBy4YearsByStateBar";
import ServiceHoursBy4YearsByStateForCustomerBar from "../../components/ServiceHoursBy4YearsByStateForCustomerBar";
import Sidebar from "../../components/Sidebar";

const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetch = async () => {
      dispatch(readAsyncStorageValues());
    };
    fetch();
  }, [dispatch]);

  return (
    <Stack sx={{ pb: 30 }}>
      <Typography variant="h4" align="center" sx={{ mb: 3, mt: 3 }}>
        Data Driven Dashboard
      </Typography>
      <Stack justifyContent="center" direction="row" spacing={0} sx={{ flexWrap: "wrap", gap: 3 }}>
        <ServiceHoursBy4YearsNationalBar />
        <ServiceHoursBy4YearsByStateBar />
        <ServiceHoursBy4YearsByStateForCustomerBar />

      </Stack>
    </Stack>
  );
};

export default Home;
