import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Bar, XAxis, YAxis, CartesianGrid, Legend, ComposedChart, Label } from "recharts";
import CircularProgress from "@mui/material/CircularProgress";
import { getServiceHoursAllStatesLast4Years } from "../../features/dashboard/actions";
import WidgetContainer from "../WidgetContainer";
import { Typography, Autocomplete, TextField } from "@mui/material";

const ServiceHoursAllStatesLast4Years = () => {
  const dispatch = useDispatch();
  const { serviceHoursAllStatesLast4Years } = useSelector((state) => state.dashboard);
  const [selectedYearsCount, setSelectedYearsCount] = useState("3 Years");

  useEffect(() => {
    const fetch = async () => {
      dispatch(getServiceHoursAllStatesLast4Years());
    };
    fetch();
  }, [dispatch]);

  const handleCountChange = (event, newValue) => {
    setSelectedYearsCount(newValue);
  };

  return serviceHoursAllStatesLast4Years.loading ? (
    <CircularProgress color="secondary" />
  ) : (
    <WidgetContainer
      title={
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          <Typography variant="h4" sx={{ mb: 3, mt: 3 }}>
            National Service Hours
          </Typography>
          <div style={{ textAlign: "center", width: 140 }}>
            <Autocomplete
              defaultValue="3 Years"
              value={selectedYearsCount}
              onChange={handleCountChange}
              options={[1, 2, 3, 4, 5, 6, 7].map((Year) => `${Year} Years`)}
              renderInput={(params) => <TextField {...params} label="Years" />}
            />
          </div>
        </div>
      }
    >
      <ComposedChart
        width={1500}
        height={500}
        data={serviceHoursAllStatesLast4Years.data}
        style={{ display: "flex", margin: 30, width: "auto", height: "auto" }}
      >
        <CartesianGrid stroke="#d6d6d6" />
        <XAxis dataKey="indianStateCode" />
        <YAxis />
        <Legend />

        {[2020, 2021, 2022, 2023, 2024].slice(-parseInt(selectedYearsCount, 10)).map((year) => (
          <Bar
            key={year}
            dataKey={String(year)}
            barSize={10}
            fill={year === 2020 ? "#5e5d5d" : year === 2021 ? "orange" : year === 2022 ? "brown" : year === 2023 ? "olive" : "#0047AB"}
          >
            {serviceHoursAllStatesLast4Years.data.map((entry, index) => (
              <Label key={`label-${index}`} content={entry.indianStateCode} position="insideTop" />
            ))}
          </Bar>
        ))}
      </ComposedChart>
    </WidgetContainer>
  );
};

export default ServiceHoursAllStatesLast4Years;