import { createSlice } from "@reduxjs/toolkit";
import {
  readCustomersByState,
  createLead,
  updateLead,
  deleteLead,
  readAllCustomersByState,
  deleteContactById,
  readMachinesByCustomerId,
  readCustomerById,
  deleteMachineFromCustomer,
  addMachineToCustomers,
  getServiceTicketsForCustomer,
} from "./actions";

const initialState = {
  loading: false,
  customersByState: {
    customers: [],
    indianStateCode: "",
    dropdownOptions: [],
  },
  allCustomersByState: {
    customers: [],
    indianStateCode: "",
    dropdownOptions: [],
  },
  serviceTicketsForCustomer: {
    data: [],
    error: null,
    loading: false,
  },
  error: null,
  success: false,
  machinesByCustomerId: [],
  customerContactData: [],
  deleteMachineFromCustomer: [],
};

const commonSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {},
  extraReducers: {
    [getServiceTicketsForCustomer.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getServiceTicketsForCustomer.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.serviceTicketsForCustomer.loading = false;
      state.serviceTicketsForCustomer.data = payload.data;
    },
    [getServiceTicketsForCustomer.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [readCustomersByState.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [readCustomersByState.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.customersByState.customers = payload.customers;
      state.customersByState.indianStateCode = payload.indianStateCode;
      state.customersByState.dropdownOptions = payload.dropdownOptions;
    },
    [readCustomersByState.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [readAllCustomersByState.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [readAllCustomersByState.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.allCustomersByState.customers = payload.customers;
      state.allCustomersByState.indianStateCode = payload.indianStateCode;
      state.allCustomersByState.dropdownOptions = payload.dropdownOptions;
    },
    [readAllCustomersByState.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [createLead.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createLead.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [createLead.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [updateLead.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateLead.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [updateLead.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [deleteLead.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteLead.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [deleteLead.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [readMachinesByCustomerId.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [readMachinesByCustomerId.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.machinesByCustomerId = payload.data;
    },
    [readMachinesByCustomerId.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [readCustomerById.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [readCustomerById.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.customerContactData = payload.data;
    },
    [readCustomerById.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [deleteContactById.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteContactById.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.customerContactData = payload.data;
    },
    [deleteContactById.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [deleteMachineFromCustomer.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteMachineFromCustomer.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.customerContactData = payload.data;
    },
    [deleteMachineFromCustomer.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [addMachineToCustomers.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [addMachineToCustomers.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [addMachineToCustomers.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default commonSlice.reducer;
