import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/slice";
import dashboardReducer from "../features/dashboard/slice";
import commonReducer from "../features/common/slice";
import customersReducer from "../features/customers/slice";
import userReducer from "../features/users/slice";
import serviceTicketReducer from "../features/servicetickets/slice";
import salesReducer from "../features/sales/slice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    dashboard: dashboardReducer,
    common: commonReducer,
    customers: customersReducer,
    users: userReducer,
    serviceTickets: serviceTicketReducer,
    sales: salesReducer,
  },
});

export default store;
