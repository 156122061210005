import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, ComposedChart, Line } from "recharts";
import CircularProgress from "@mui/material/CircularProgress";
import { getServiceHoursByMonths } from "../../features/dashboard/actions";
import WidgetContainer from "../WidgetContainer";

const ServiceHoursByMonths = () => {
  const dispatch = useDispatch();
  const { serviceHoursByMonths } = useSelector((state) => state.dashboard);
  useEffect(() => {
    const fetch = async () => {
      dispatch(getServiceHoursByMonths());
    };
    fetch();
  }, [dispatch]);

  return serviceHoursByMonths.loading ? (
    <CircularProgress color="secondary" />
  ) : (
    <WidgetContainer title="Service Hours Last 12 Months National">
      <ComposedChart
        width={900}
        height={400}
        data={serviceHoursByMonths.data}
        margin={{
          top: 20,
          right: 50,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid stroke="#d6d6d6" />
        <XAxis dataKey="monthName" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="hours_spent" stroke="#ff7300" label={{ position: "right" }} />
      </ComposedChart>
    </WidgetContainer>
  );
};

export default ServiceHoursByMonths;
