import { createSlice } from "@reduxjs/toolkit";
import { userLogin } from "./actions";

const userInfo = JSON.parse(localStorage.getItem("USER_DETAILS"));
const userRole = JSON.parse(localStorage.getItem("USER_CURRENT_ROLE"));

const initialState = {
  loading: false,
  userInfo: userInfo || null,
  userCurrentRole: userRole || null,
  error: null,
  success: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem("USER_DETAILS"); // delete token from storage
      localStorage.removeItem("USER_CURRENT_ROLE"); // delete role from storage
      state.loading = false;
      state.userInfo = null;
      state.userCurrentRole = null;
      state.error = null;
    },
  },
  extraReducers: {
    // login user
    [userLogin.pending]: (state) => {
      state.loading = true;
      state.userInfo = null;
      state.error = null;
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userInfo = payload;
      state.userCurrentRole = payload.roles[0];
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.loading = false;
      state.userInfo = null;
      state.userCurrentRole = null;
      state.error = "Username or password is incorrect!";
    },
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
