import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Box, Stack, TextField, IconButton, Tooltip, CircularProgress } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { readAsyncStorageValues } from "../../features/common/actions";
import { exportToCsv } from "../../utils/utilFunctions";
import CloseIcon from "@mui/icons-material/Close";

const MachineModel = () => {
  const dispatch = useDispatch();
  const { machineModels, loading } = useSelector((state) => state.common);
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [isDataLoading, setIsDataLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      await dispatch(readAsyncStorageValues());
      setIsDataLoading(false);
    };
    fetchData();
  }, [dispatch]);

  const renderDetailsButton = (params) => {
    return (
      <strong>
        <Tooltip title="View">
          <Button
            startIcon={<ZoomInIcon />}
            color="primary"
            size="large"
            style={{  fontWeight: "bold" }}
            onClick={() => navigate("/edit-machine-model/" + params.row.id)}
          />
        </Tooltip>
        <Tooltip title="Edit">
          <Button
            startIcon={<EditIcon />}
            color="primary"
            size="large"
            style={{ marginLeft: -12, fontWeight: "bold" }}
            onClick={() => navigate("/edit-machine-model/" + params.row.id, { state: { enableEdit: true } })}
          />
        </Tooltip>
      </strong>
    );
  };

  const columns = [
    { field: "model", headerName: "Machine Model", width: 100, flex: 0.1 },
    { field: "manufacturer", headerName: "Manufacturer", width: 150, flex: 0.1 },
    {
      field: "col6",
      headerName: "",
      width: 300,
      renderCell: renderDetailsButton,
      disableClickEventBubbling: false,
      sortable: false,
      filterable: false,
    },
  ];

  const downloadCsv = (e) => {
    e.preventDefault();
    const headers = ["Id, Model Name, Manufacturer"];
    const keys = ["id", "model", "manufacturer"];
    exportToCsv(headers, keys, machineModels, "Machine_Models");
  };

  const handleSearchChange = (event) => setSearchTerm(event.target.value);
  const handleClearSearch = () => setSearchTerm("");

  return (
    <div>
      <Stack sx={{ mt: 4, mx: 4 }} direction={"row"} justifyContent={"space-between"}>
        <TextField
          style={{ minWidth: 300, boxShadow: "0px 3px 15px rgba(0,0,0,0.1)" }}
          label="Search"
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {searchTerm ? (
                  <IconButton edge="end" onClick={handleClearSearch} size="small">
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                )}
              </InputAdornment>
            ),
          }}
        />
        <Stack direction={"row"} gap={2}>
          <Button
            variant="contained"
            onClick={() => navigate("/add-machine-model", { state: { enableEdit: true } })}
            startIcon={<AddIcon />}
            color="primary"
            sx={{ textTransform: "capitalize", height: 50, ml: 2 }}
          >
            Add
          </Button>
          <Button
            variant="contained"
            sx={{ textTransform: "capitalize", height: 50, ml: 2 }}
            onClick={downloadCsv}
            // disabled={allCustomersByState.customers.length ? false : true}
          >
            Export to CSV
          </Button>
        </Stack>
      </Stack>
      <Box sx={{ height: 600, px: 3, mt: 4 }}>
        {isDataLoading ? (
          <CircularProgress
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        ) : (
          <DataGrid
            rows={machineModels.filter((item) => Object.values(item).join(" ").toLowerCase().includes(searchTerm.toLowerCase()))}
            columns={columns}
            pageSize={5}
            localeText={{ noRowsLabel: "No Data." }}
            style={{ boxShadow: "0px 3px 15px rgba(0,0,0,0.2)", marginLeft: 5 }}
          />
        )}
      </Box>
    </div>
  );
};

export default MachineModel;
