import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Stack, Typography } from "@mui/material";
import { readAsyncStorageValues } from "../../features/common/actions";
import ServiceHoursByMonths from "../../components/ServiceHoursByMonths";

const Last12MonthsDashboard = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetch = async () => {
      dispatch(readAsyncStorageValues());
    };
    fetch();
  }, [dispatch]);

  return (
    <Stack sx={{ pb: 30 }}>
      <Typography variant="h4" align="center" sx={{ mb: 3, mt: 3 }}>
        Data Driven Dashboard
      </Typography>
      <Stack justifyContent="center" direction="row" spacing={0} sx={{ flexWrap: "wrap", gap: 3 }}>
        <ServiceHoursByMonths />
      </Stack>
    </Stack>
  );
};

export default Last12MonthsDashboard;
