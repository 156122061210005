import { useSelector } from "react-redux";
import { Typography, Avatar, Stack } from "@mui/material";

const Profile = () => {
  const { userInfo } = useSelector((state) => state.auth);

  return (
    <Stack justifyContent="center" alignItems="center" sx={{ marginY: 10 }}>
      <Avatar sx={{ height: 100, width: 100, mb: 3 }}>
        <Typography variant="h2" align="center">
          {userInfo?.username.charAt(0).toUpperCase()}
        </Typography>
      </Avatar>
      <Typography variant="h6" align="center">
        Welcome <strong>{userInfo?.username}!</strong> You can view this page
        because you're logged in
      </Typography>
    </Stack>
  );
};

export default Profile;
