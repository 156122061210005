import { useSelector } from "react-redux";
import { NavLink, Outlet } from "react-router-dom";
import { Typography, Stack, Link } from "@mui/material";

const ProtectedRoute = () => {
  const { userInfo } = useSelector((state) => state.auth);

  // show unauthorized screen if no user is found in redux store
  if (!userInfo) {
    return (
      <Stack>
        <Typography variant="h4" align="center" sx={{ mb: 3, mt: 8 }}>
          Unauthorized :(
        </Typography>
        <Typography variant="body1" align="center">
          <Link component={NavLink} to="/login" variant="body1">
            Login
          </Link>{" "}
          to gain access
        </Typography>
      </Stack>
    );
  }

  return <Outlet />;
};

export default ProtectedRoute;
